import Electricians from "views/Electricians"
import Layout from "components/Layout"
import React from "react"
import SEO from "components/seo"

const ElectriciansPage = () => {
  return (
    <Layout>
      <SEO
        title="Najděte si nejbližšího elektrikáře"
        description="Máte strach z montáže? Pomůžeme Vám najít specialistu, který Vám namontuje vypínač raz dva!"
      />
      <Electricians />
    </Layout>
  )
}

export default ElectriciansPage
